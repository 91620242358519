






























  INSTANCE_STYLESELECTS = []

  # События:
  #  * opened
  #  * closed

  export default {
    props: {
      items:
        type: Array
        default: ->
          [{value: 2, label: '2'} , {value: 3, label: '3'} ]
      value:
        type: [String, Number]

      inputName:
        type: String
        default: ''

      placeholderText:
        type: String
        default: ''


      containerClasses:
        type: Array
        default: -> (['input', 'input--select'])
    }

    mounted: ->
      @pushToInstance()
      @findSelectedItem()

    beforeDestroy: ->
      @removeFromInstance()

    data: ->
      selectedItem: null
      showSelectorContainer: false
      isParentLarger: false
      selectContainerHeight: null

    computed:
      selectedItemLabel: ->
        return @placeholderText unless @selectedItem?
        @selectedItem.label

      selectedItemContainerClasses: ->
        active: @showSelectorContainer
        'input__select-field--placeholder': @isShowPlaceholder

      itemsContainerStyle: ->
        r = {}
        r.overflow = 'hidden' unless @showSelectorContainer
        r.height = @itemsContainerHeight if @itemsContainerHeight?
        r

      itemsContainerClass: ->
        'input__select-dropdown--top': @isOpenOnTop

      itemsContainerHeight: ->
        if @isParentLarger
          check = @$dropdown().height() > @$select().offsetParent().height() / 2 + @$field().height()
          if check
            @$select().offsetParent().height() / 2 - @$field().height()
          else
            null
        else
          null

      isOpenOnTop: ->
        if @isParentLarger
          return false unless @$el?
          
          rect = @$refs.field.getBoundingClientRect()
          bottomHeight = window.innerHeight - @$refs.field.offsetHeight - rect.top
          topHeight = rect.top

          if bottomHeight >= @$refs.dropdown.offsetHeight
            false
          else if topHeight >= @$refs.dropdown.offsetHeight
            true
          else
            topHeight >  bottomHeight
        else
          return false unless @$el?
          ($(window).scrollTop() + $(window).height()) < (@$select().offset().top + @$field().outerHeight() + @$dropdown().height())

      isShowInput: ->
        @inputName? && @inputName.trim() != ''

      isShowPlaceholder: ->
        @placeholderText? && @placeholderText.trim() != ''

    methods:
      findSelectedItem: ->
        if @value?
          @selectedItem = @items.find((i) => i.value == @value)
          if !@selectedItem
            @selectedItem = @items.find((i) => i.value == parseInt(@value))
        else
          @selectedItem = null

      optionNameKey: (value, index) ->
        "#{value}__#{index}"

      getItemClasses: (item) ->
        selected: item == @selectedItem

      clickSelectedItemHandler: ->
        @showSelectorContainer = !@showSelectorContainer

      clickItemHandler: (item, $event) ->
        @hideContainer()
        @selectedItem = item
        @$emit('input', @selectedItem.value)

      showContainer: ->
        @showSelectorContainer = true

      hideContainer: ->
        @showSelectorContainer = false

      getIndexOfInstance: ->
        INSTANCE_STYLESELECTS.indexOf(@)

      pushToInstance: ->
        return if @getIndexOfInstance() != -1
        INSTANCE_STYLESELECTS.push(@)

      removeFromInstance: ->
        index = @getIndexOfInstance()
        return if index == -1
        INSTANCE_STYLESELECTS.splice(index, 1)

      hideOtherContainers: ->
        currentIndex = @getIndexOfInstance()
        INSTANCE_STYLESELECTS.forEach (instance, index) =>
          return if index == currentIndex

          instance.hideContainer()

      changeIsParentLarger: ->
        if @showSelectorContainer
          @isParentLarger = @$select().offsetParent().height() > @$field().outerHeight()
        else
          @isParentLarger = false

      $select: ->
        @__$select ||= $(@$refs.select)

      $dropdown: ->
        @__$dropdown ||= $(@$refs.dropdown)

      $field: ->
        @__$field ||= $(@$refs.field)

    watch:
      showSelectorContainer: (newValue, oldValue) ->
#        @$nextTick ->
#          @changeIsParentLarger()
#          if newValue
#            @$emit('opened')
#          else
#            @$emit('closed')
        @hideOtherContainers if newValue
  }
